<template>
  <div class="wrapper-container">
    <h2 class="header-2">Add features</h2>
    <section class="customize">
      <div class="options">
        <OptionsSwitcher
          :tabs="['Face', 'Hair', 'Facial Hair']"
          :selected="tab"
          @change="
            (e) => {
              emit('clickedTab', e);
            }
          "
        />
        <div v-if="tab === 'Face'" class="item-picker-container" ref="items">
          <div
            v-for="face in shownItems"
            v-html="face.src"
            :key="face.id"
            :style="{
              background: store.state.avatar.skin_tone,
              fill: 'transparent',
            }"
            class="face-item"
            :class="{ 'highlight-color-active': face.id === faceSelected }"
            @click="selectFace(face.id)"
          />
        </div>
        <div v-else class="item-picker-container" ref="items">
          <div class="deselect" @click="deselectItem()">none</div>
          <div
            v-for="item in shownItems"
            v-html="item.src"
            :key="item.id"
            class="item"
            :class="{ 'highlight-item-active': shouldHighlightItem(item.id) }"
            @click="selectItem(item.id, false)"
          />
        </div>
        <div style="display: flex; column-gap: 40px">
          <button
            v-show="shouldDisplayUpBtn"
            class="more-btn"
            style="transform: rotate(180deg)"
            @click="upClick"
          >
            <img :src="more" alt="up btn" />
          </button>
          <button
            v-show="shouldDisplayDownBtn"
            class="more-btn"
            @click="downClick"
          >
            <img :src="more" alt="down btn" />
          </button>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; align-items: center">
        <AvatarCircle
          size="350"
          :avatar="store.state.avatar"
          should-show-third
        />
        <div class="color-picker-container">
          <div
            class="color-item"
            :class="{ 'highlight-color-active': shouldHighlightColor(color) }"
            v-for="color in colorGroup"
            :key="color"
            :style="{ background: color }"
            @click="colorClickHandler(color)"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import OptionsSwitcher from "@/components/OptionsSwitcher.vue";
import AvatarCircle from "@/components/AvatarCircle.vue";
import more from "@/assets/icons/more.svg";
import faces from "@/data/avatar/avatarFaces";
import hairs from "@/data/avatar/avatarHairs";
import facialHairs from "@/data/avatar/avatarFacialHairs";
import accessories from "@/data/avatar/avatarAccessories";
import { avatarNames } from "@/store/modules/avatar";
const props = defineProps({
  tab: {
    required: true,
  },
});
const emit = defineEmits({
  clickedTab: (path) => typeof path === "string",
});
const colors = {
  Hair: ["#ECE6E6", "#F5E198", "#BF580F", "#9B6C51", "#97301A", "#2B2928"],
  "Facial Hair": [
    "#ECE6E6",
    "#F5E198",
    "#BF580F",
    "#9B6C51",
    "#97301A",
    "#2B2928",
  ],
  Accessories: [
    "#EE4234",
    "#F0E1D7",
    "#FFD850",
    "#2F679A",
    "#7A5011",
    "#2B2928",
  ],
};

const store = useStore();
const router = useRouter();
const items = ref(null);

if (!store.state.avatar.base) {
  router.push("/personalize/avatar-builder/1");
}

const colorGroup = computed(() => colors[props.tab]);

const faceSelected = computed({
  get: () => store.state.avatar.face_type,
  set: (value) => store.commit(avatarNames.setFace, value),
});

const hairStyleSelected = computed({
  get: () => store.state.avatar.hair_type,
  set: (value) => store.commit(avatarNames.setHairType, value),
});
const hairColorSelected = computed({
  get: () => store.state.avatar.hair_color,
  set: (value) => store.commit(avatarNames.setHairColor, value),
});
// const hairColorSelected = ref(hairSelected.value.color)

const facialHairTypeSelected = computed({
  get: () => store.state.avatar.facial_hair_type,
  set: (value) => {
    store.commit(avatarNames.setFacialHairType, value);
  },
});
const facialHairColorSelected = computed({
  get: () => store.state.avatar.facial_hair_color,
  set: (value) => store.commit(avatarNames.setFacialHairColor, value),
});
// const facialHairColorSelected = ref(facialHairSelected.value.color)

// const accessoriesSelected = computed({
//   get: () => store.state.avatar.accessories,
//   set: (value) => store.commit(avatarNames.setAccessories, value)
// })
// const accessoriesColorSelected = ref(accessoriesSelected.value.color)

const currentLine = ref(0);
const shownItems = computed(() => {
  if (props.tab === "Face") return faces;
  else if (props.tab === "Hair") return hairs;
  else if (props.tab === "Facial Hair") return facialHairs;
  else return accessories;
});

const shouldDisplayUpBtn = computed(() => currentLine.value > 0);
const shouldDisplayDownBtn = computed(() => {
  if (props.tab === "Facial Hair") {
    return currentLine.value < facialHairs.length / 3 - 2;
  } else if (props.tab === "Hair") {
    return currentLine.value < hairs.length / 3 - 2;
  }
  return false;
});

const scrollItems = () => {
  if (props.tab === "Facial Hair") {
    items.value.scrollTop = currentLine.value * 160;
  } else if (props.tab === "Hair") {
    if (currentLine.value === 1) {
      items.value.scrollTop = currentLine.value * 145;
    } else {
      items.value.scrollTop = currentLine.value * 170;
    }
  }
};

const downClick = () => {
  currentLine.value++;
  scrollItems();
};
const upClick = () => {
  currentLine.value--;
  scrollItems();
};

const shouldHighlightColor = (color) => {
  if (props.tab === "Hair") {
    return hairColorSelected.value === color;
  } else if (props.tab === "Facial Hair") {
    return facialHairColorSelected.value === color;
  }
  // else if (props.tab === 'Accessories') {
  //   return accessoriesColorSelected.value === color
  // }
};
const shouldHighlightItem = (itemId) => {
  if (props.tab === "Hair") {
    return hairStyleSelected.value === itemId;
  } else if (props.tab === "Facial Hair") {
    return facialHairTypeSelected.value === itemId;
  }
  // else {
  //   return accessoriesSelected.value.type.includes(itemId)
  // }
};

const selectFace = (id) => {
  faceSelected.value = id;
};
const deselectItem = () => {
  if (props.tab === "Hair") {
    hairStyleSelected.value = "";
    hairColorSelected.value = "#ECE6E6";
  }
  if (props.tab === "Facial Hair") {
    facialHairTypeSelected.value = "";
    facialHairColorSelected.value = "#ECE6E6";
  }
};
const selectItem = (ids, isManual) => {
  if (props.tab === "Hair") {
    if (!isManual && hairStyleSelected.value === ids) {
      hairStyleSelected.value = "";
      hairColorSelected.value = "#ECE6E6";
    } else {
      hairStyleSelected.value = ids;
    }
  } else if (props.tab === "Facial Hair") {
    if (!isManual && facialHairTypeSelected.value === ids) {
      facialHairTypeSelected.value = "";
      facialHairColorSelected.value = "#ECE6E6";
    } else {
      console.log("CLICK:", ids);
      facialHairTypeSelected.value = ids;
    }
  }
  // else {
  //   if (typeof ids === 'object') {
  //     const newIds = []
  //     ids.forEach((id) => {
  //       if (accessoriesSelected.value.type.includes(id)) newIds.push(id)
  //     })
  //     accessoriesSelected.value = { type: newIds, color: accessoriesColorSelected.value }
  //   } else {
  //     if (accessoriesSelected.value.type.includes(ids)) {
  //       accessoriesSelected.value.type = accessoriesSelected.value.type.filter(item => item !== ids)
  //     } else {
  //       accessoriesSelected.value = {
  //         type: [...accessoriesSelected.value.type, ids],
  //         color: accessoriesColorSelected.value
  //       }
  //     }
  //   }
  // }
};

const colorClickHandler = (color) => {
  if (props.tab === "Hair") {
    hairColorSelected.value = color;
    selectItem(hairStyleSelected.value, true);
  } else if (props.tab === "Facial Hair") {
    facialHairColorSelected.value = color;
    selectItem(facialHairTypeSelected.value, true);
  }
  // else if (props.tab === 'Accessories') {
  //   accessoriesColorSelected.value = color
  //   selectItem(accessoriesSelected.value.type, true)
  // }
};
</script>

<style scoped>
.wrapper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-2 {
  margin: 0 0 10px;
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
  font-size: 36px;
  color: black;
}

.customize {
  display: flex;
  margin: 90px 0;
  column-gap: 70px;
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-picker-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 430px;
  height: 300px;
  margin-top: 50px;
  padding: 15px 0;
  row-gap: 50px;
  column-gap: 50px;
  scroll-behavior: smooth;
  overflow-y: hidden;
}

.face-item,
.item {
  width: 100px;
  cursor: pointer;
}
.deselect {
  width: 100px;
  cursor: pointer;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #e4e4e4;
  color: var(--grey, #b7b8b7);
  text-align: center;

  font-family: Helvetica Neue LT Std;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.face-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  border-radius: 50%;
}

.face-item >>> svg {
  margin-top: 5px;
  width: 70%;
}

.more-btn {
  margin-top: 20px;
  border: 0;
  background: none;
  cursor: pointer;
}

.color-picker-container {
  display: flex;
  margin-top: 50px;
  column-gap: 15px;
}

.color-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.highlight-item-active {
  transition: box-shadow 0.3s ease;
  outline: 3px solid white;
  box-shadow: 0 0 18px rgba(255, 130, 23, 0.65);
  border-radius: 40%;
  padding: 10px;
}

.highlight-color-active {
  transition: box-shadow 0.3s ease;
  outline: 3px solid white;
  box-shadow: 0 0 18px rgba(255, 130, 23, 0.65);
}
</style>
